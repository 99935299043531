import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import Web3 from "web3";
import ConnectWallet from "./ConnectWallet";
import { useDispatch, useSelector } from "react-redux";
import YezABI from "../ABI/YezABI.json";
import config from "../config/config";
import { walletClientToSigner } from "../helper/getprovider";
import { useWalletClient, useNetwork, useDisconnect, useAccount } from "wagmi";
import { setWallet, setBalances } from "../reducers/Actions";
import { shortText, formatNumber } from "../helper/custom";
import { getAllowanceBalance } from "../helper/common";
import JoinNow from "./JoinNow";

export default function Navbar({ address }) {
  const dispatch = useDispatch();
  const { chain } = useNetwork();
  let chainId = config.NetworkId;
  const { disconnect, isSuccess, status } = useDisconnect();
  const navigate = useNavigate();
  const walletConnection = useSelector((state) => state.walletConnection);
  const userBalances = useSelector((state) => state.balances);
  console.log(userBalances, "userBalances");
  const { data: walletClient } = useWalletClient({ chainId: config.networkId });
  const [useraddress, setuseraddress] = useState("");

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  useEffect(() => {
    setConnection();
    // eslint-disable-next-line
  }, [walletClient, chain]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        window.location.reload();
      });
    }
  }, []);

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      localStorage.clear();
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no"
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    }
  });

  async function setConnection() {
    // setisUser(false);
    if (walletClient && chain && parseInt(chain.id) === config.networkId) {
      var { transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      var userAddr =
        walletClient && walletClient.account && walletClient.account.address
          ? walletClient.account.address
          : "";
      // var userAddr = "0xdE3af73565c4EFB2823Cacb40053E3BfB4BC6Bd0";
      var chainVal = chain && chain.id ? chain.id : config.networkId;
      const YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
      let isUserExits = await YezContract.methods.users(userAddr).call();
      console.log(isUserExits.isExist, "isUserExits");
      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: transport,
          connect: "yes",
          isUserExits: isUserExits.isExist
        })
      );
      setuseraddress(userAddr);

      setTimeout(async function () {
        try {
          var { balanceOf, balanceOf1, allowance, bnbBal } =
            await getAllowanceBalance();
          balanceOf = formatNumber(balanceOf);
          bnbBal = formatNumber(bnbBal);

          dispatch(
            setBalances({
              tokenbalance: balanceOf,
              ownTokenbalance: balanceOf1,
              tokenallowance: allowance,
              balance: bnbBal,
              isLoad: "yes"
            })
          );
        } catch (err) {}
      }, 500);
    } else {
      var chainVal1 =
        localStorage.getItem("network") &&
        parseInt(localStorage.getItem("network")) > 0
          ? parseInt(localStorage.getItem("network"))
          : config.networkId;
      var web3 = new Web3(config.rpcURl);
      dispatch(
        setWallet({
          network: chainVal1,
          web3: web3,
          address: "",
          provider: "",
          connect: "no"
        })
      );
    }
  }

  useEffect(() => {
    if (chain && chain.id !== config.NetworkId) {
      dispatch(
        setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true"
        })
      );
    } else {
      dispatch(
        setWallet({
          network: chainId,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
          isChange: "false"
        })
      );
    }
  }, [walletClient, chain?.network]);

  function logout() {
    disconnect();
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                <a
                  className="primary_btn"
                  href={require("../assets/pdf/YEZ_litepaper_1.pdf")}
                  target="_blank"
                >
                  <span>Whitepaper</span>
                </a>
                {/* After Login Menus */}
                {walletConnection &&
                walletConnection.address !== "" &&
                walletConnection.connect === "yes" ? (
                  <>
                    <div className="dropdown">
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>{shortText(walletConnection.address)}</span>
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>BNB Balance:</label>
                            <span className="ms-2">
                              {userBalances.balance
                                ? userBalances.balance.toFixed(2)
                                : 0}{" "}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>BUSD Balance:</label>
                            <span className="ms-2">
                              {userBalances.tokenbalance
                                ? userBalances.tokenbalance.toFixed(2)
                                : 0}{" "}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>YEZ Balance:</label>
                            <span className="ms-2">
                              {userBalances.ownTokenbalance
                                ? userBalances.ownTokenbalance.toFixed(2)
                                : 0}{" "}
                            </span>
                          </a>
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => disconnect()}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>

                    {walletConnection.isUserExits ? (
                      <Link to="/dashboard" className="w-100">
                        {" "}
                        <button
                          className="primary_btn"
                          data-bs-dismiss="offcanvas"
                        >
                          <span>Dashboard</span>
                        </button>
                      </Link>
                    ) : (
                      <>
                        {" "}
                        <button
                          data-bs-dismiss="offcanvas"
                          className="primary_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#joinnow_modal"
                        >
                          <span>Join Now</span>
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      data-bs-dismiss="offcanvas"
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      <span>Connect Wallet</span>
                    </button>
                  </>
                )}
                <>
                  {" "}
                  <a
                    href="http://www.yezbitcoin.team"
                    target="_blank"
                    className="w-100"
                  >
                    {" "}
                    <button className="primary_btn" data-bs-dismiss="offcanvas">
                      <span>Our Team</span>
                    </button>
                  </a>
                </>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <ConnectWallet />

      {/* Join Now Modal */}
      <JoinNow address={address} />
    </>
  );
}
