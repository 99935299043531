import React from "react";
import logo from "../assets/images/logo.svg";
import config from "../config/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            <div className="footer_about">
              <img src={logo} alt="Logo" className="img-fluid" />
            </div>
            <div className="footer_panel_top_center">
              <p>Smart-contract address:</p>
              <a
                href={config.txLink + "/address/" + config.YezContAddr}
                target="_blank"
              >
                {config.YezContAddr}
              </a>
            </div>
            <ul className="footer_social_links">
              <li>
                <a href="https://t.me/yezbitcoin_official" target="_blank">
                  <i class="fa-solid fa-paper-plane"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61559127598836"
                  target="_blank"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/YEZBitcoin_2024" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/yezbitcoin_2024/"
                  target="_blank"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@YEZBitcoin_2024"
                  target="_blank"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="footer_panel_bottom">
            <ul className="footer_menu_links">
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
            <ul className="footer_menu_links">
              <li>
                <a href="#">Terms of service</a>
              </li>
            </ul>
            <p>All Rights Reserved</p>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
