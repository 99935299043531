import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastAlert(errorType, message, id) {
  if (errorType == "error") {
    toast.error(message, {
      autoClose: 2000,
      theme:"dark",
      toastId: id,
      className: 'custom-toast-container', // Add custom class for styling
      style: { fontSize: "15px", /* other size-related styles */ }
      // position: toast.POSITION.TOP_RIGHT
    });
  } else if (errorType == "success") {
    toast.success(message, {
      autoClose: 2000,
      theme:"dark",
      toastId: id,
      className: 'custom-toast-container', // Add custom class for styling
      style: { fontSize: "15px", /* other size-related styles */ }
      // position: toast.POSITION.TOP_RIGHT
    });
  } else if (errorType == "warning") {
    toast.warn(message, {
      autoClose: 2000,
      theme:"dark",
      toastId: id,
      className: 'custom-toast-container', // Add custom class for styling
      style: { fontSize: "15px", /* other size-related styles */ }
      // position: toast.POSITION.TOP_RIGHT
    });
  }
}
