import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toastAlert } from "../../helper/toastAlert";
import YezABI from "../../ABI/YezABI.json";
import config from "../../config/config";
import RotateLoader from "react-spinners/RotateLoader";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "#20403e",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Package({ poolData }) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setLoading] = useState(false);

  // const getRemainMonthReward = async () => {
  //   try {
  //     if (walletConnection.address) {
  //       poolData
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleClaimYez = async (poolData) => {
    try {
      if (walletConnection.address) {
        setLoading(true);
        let address = walletConnection.address;
        let web3 = walletConnection.web3;
        var YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
        console.log(poolData, "poolData?.poolId");
        let lastCliameddate = poolData?.lastCliameddate;
        console.log(
          lastCliameddate,
          "lastCliameddate",
          new Date().getTime(),
          new Date(lastCliameddate).getTime()
        );
        if (new Date().getTime() >= new Date(lastCliameddate).getTime()) {
          let timeDiff =
            new Date().getTime() - new Date(lastCliameddate).getTime();
          // let monthDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
          let monthDiff = Math.floor(timeDiff / (1000 * 60 * 5));

          if (monthDiff > 0) {
            let bnbBalance = await web3.eth.getBalance(address);
            bnbBalance = web3.utils.fromWei(bnbBalance, "ether");
            const gasLimit = await YezContract.methods
              .cliamStakerewardReward(poolData?.poolId)
              .estimateGas({ from: address });
            const gasFee = gasLimit * (await web3.eth.getGasPrice());
            console.log(gasFee, "gasFee", bnbBalance);
            if (bnbBalance < gasFee / 1e18) {
              setLoading(false);
              toastAlert("error", "Insufficient BNB Balance for Gas Fee");
              return false;
            }
            await YezContract.methods
              .cliamStakerewardReward(poolData?.poolId)
              .send({ from: address })
              .then((result) => {
                if (result.status && result.transactionHash) {
                  toastAlert("success", "Reward Claimed Successfully");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((err) => {
                setLoading(false);
                toastAlert("error", "Transaction rejected by user");
              });
          } else {
            setLoading(false);
            toastAlert("error", "Already Claimed for this month Amount");
          }
        } else {
          setLoading(false);
          toastAlert("error", "90 days Not completed");
        }
      } else {
        toastAlert("error", "Connect your wallet ");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="packages_div">
        <div className="packages_div_left packages_div_inner">
          <h2>Investment Amount</h2>
          <h3>
            {poolData?.amountInvestedUsdt.toFixed(2)} USDT /{" "}
            {poolData?.amountInvested.toFixed(2)} YEZ
          </h3>
        </div>

        <div className="packages_div_middle packages_div_inner">
          <h2>Remaining Days</h2>
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
              // style={{ width: "50%" }}
              style={{ width: `${(poolData?.leftDays / 90) * 100}%` }}
            ></div>
          </div>
          <div className="progress_info">
            <p>{((poolData?.leftDays / 90) * 100).toFixed(1)}%</p>
            <p>
              {poolData?.leftDays} <span>/ 90 Days</span>
            </p>
          </div>
        </div>

        <div className="packages_div_right packages_div_inner">
          <div>
            <h2>
              {console.log(
                poolData?.monthReward,
                "poolData?.monthReward",
                poolData?.RewardAmountClaimed
              )}
              Received Rewards{" "}
              <span style={{ color: "#fff", float: "right" }}>
                {poolData?.RewardAmountClaimed.toFixed(2)} YEZ
              </span>
            </h2>
            {console.log(
              Number(poolData?.ClaimCount),
              poolData?.month,
              "eeeeeeeee1"
            )}
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                // style={{ width: "50%" }}
                style={{
                  width: `${
                    ((Number(poolData?.ClaimCount) + poolData?.month) / 20) *
                    100
                  }%`
                }}
              ></div>
            </div>
            <div className="progress_info">
              <p>
                {poolData?.ClaimCount + poolData?.month}{" "}
                <span> / 20 Months</span>
              </p>
              <p>
                {(poolData?.monthReward).toFixed(2)} YEZ{" "}
                <span>
                  {" "}
                  /{" "}
                  {poolData?.amountInvested * 2 -
                    poolData?.RewardAmountClaimed}{" "}
                  YEZ
                </span>
              </p>
            </div>
          </div>
          {/* new Date(poolData?.lastCliameddate) <= new Date() && */}
          {poolData?.monthReward > 0 && (
            <>
              <button
                className="primary_btn"
                onClick={() => handleClaimYez(poolData)}
                disabled={poolData?.ClaimCount == 20 ? true : false}
              >
                <span>Claim</span>
              </button>
            </>
          )}
        </div>
      </div>
      {loading && (
        <div className="loadBefore">
          <RotateLoader
            color={"#9dfffa"}
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
