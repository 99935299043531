import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import config from "../config/config.js";
import Web3 from "web3";
import YezABI from "../ABI/YezABI.json";
import { useSelector } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const { address } = useParams();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [homeData, setHomeData] = useState({
    totalUsers: 0,
    totalInvested: 0,
    totalUsers24: 0
  });
  useEffect(() => {
    AOS.init();
  }, []);

  var activity_slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = async () => {
    try {
      const web3 = new Web3(config.rpcUrl);
      var YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
      const totalUsers = await YezContract.methods.totalUsers().call();
      const totalInvested = await YezContract.methods.totalInvested().call();
      const totalUsers24 = await YezContract.methods
        .getUsersJoinedLast24Hours()
        .call();
      setHomeData({
        ...homeData,
        totalUsers: totalUsers,
        totalInvested: totalInvested / 1e18,
        totalUsers24: totalUsers24
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      {walletConnection.address && (
        <div className="stats_panel_wrapper">
          <div className="container">
            <div className="stats_panel">
              {/* <div>
                <label>All participants:</label>
                <label>{homeData?.totalUsers}</label>
              </div>
              <div>
                <label>Today Joined Users:</label>
                <label>{homeData?.totalUsers24}</label>
              </div> */}
              {/* <div>
                <label>Total Investment:</label>
                <label>{Number(homeData?.totalInvested).toFixed(2)}</label>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <Navbar address={address} />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="banner_content">
                <h5>YEZ BITCOIN Brings You a</h5>
                <h2>Al-Enabled Application Development Projects</h2>
                {/* <p>
                  Earn maximum by investing minimum. Discover the newest
                  treasures with <span>YEZBITCOIN</span>
                </p> */}
                <img
                  src={require("../assets/images/banner_img.png")}
                  alt="Banner"
                  className="img-fluid"
                />

                {walletConnection.isUserExits ? (
                  <Link to="/dashboard">
                    {" "}
                    <button className="primary_btn">
                      <span>Dashboard</span>
                    </button>
                  </Link>
                ) : walletConnection.address ? (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#joinnow_modal"
                  >
                    <span class="text">Get Started</span>
                  </button>
                ) : (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <span class="text">Connect Wallet</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="community_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2 className="main_title text-center">Our Features</h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="features_div features_div_line">
                  <img
                    src={require("../assets/images/icon_01.png")}
                    alt="Img"
                    className="img-fluid"
                  />
                  <h2>Gaming platform</h2>
                  <p className="para">
                    YEZBITCOIN crypto gaming, players genuinely own their YEZ
                    BITCOIN assets. This is because these assets are stored on a
                    blockchain, which is a secure and decentralized ledger. This
                    means that players can trade or sell their in-game assets to
                    other players, or even convert to other utility usage
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="features_div features_div_line">
                  <img
                    src={require("../assets/images/icon_02.png")}
                    alt="Img"
                    className="img-fluid"
                  />
                  <h2>YEZ BITCOIN DEX</h2>
                  <p className="para">
                    YEZ BITCOIN decentralized exchange (better known as a DEX)
                    is a peer-to-peer marketplace where transactions occur
                    directly between crypto traders. YEZ BITCOIN DEXS fulfill
                    one of crypto's core possibilities: fostering financial
                    transactions that aren't officiated by banks, brokers,
                    payment processors, or any other kind of intermediary.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="features_div">
                  <img
                    src={require("../assets/images/icon_03.png")}
                    alt="Img"
                    className="img-fluid"
                  />
                  <h2>YEZBITCOIN BLOCKCHAIN</h2>
                  <p className="para">
                    YEZBITOIN Blockchain brings trust in data integrity and in
                    the recommendations that Al provides. Using blockchain to
                    store and distribute Al models provides an audit trail, and
                    pairing blockchain and Al can enhance data security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2 className="main_title">About us</h2>
                <p
                  className="para"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  In today's rapidly evolving technological landscape,
                  artificial intelligence (AI) has emerged as a game-changer,
                  transforming the way businesses operate and interact with
                  their customers. Our dedicated team of experts specializes in
                  harnessing the power of Al to create innovative and
                  intelligent applications that drive business growth and
                  enhance user experiences. YEZ BITCOIN we pride ourselves on
                  our proficiency in Al technologies and frameworks. Our
                  experienced team has successfully developed a wide array of
                  Al-enabled applications across various domains.
                </p>
              </div>
              <div
                className="col-lg-6 text-center"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/img_01.png")}
                  alt="Img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="benefits_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 text-center"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/img_02.png")}
                  alt="Img"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h2 className="main_title">Our Benefits</h2>
                <ul className="benefits_list">
                  <li>
                    Stake for 90 days, will get monthly 10% for 20 months.
                  </li>
                  <li>Get benefits of 10 % for direct referral rewards.</li>
                  <li>Get level Rewards.</li>
                </ul>
                {walletConnection.isUserExits ? (
                  <Link to="/dashboard">
                    {" "}
                    <button className="primary_btn mt-5">
                      <span>Dashboard</span>
                    </button>
                  </Link>
                ) : walletConnection.address ? (
                  <>
                    {" "}
                    <button
                      className="primary_btn mt-5"
                      data-bs-toggle="modal"
                      data-bs-target="#joinnow_modal"
                    >
                      <span>Join Now</span>
                    </button>
                  </>
                ) : (
                  <button
                    className="primary_btn mt-5"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <span class="text">Connect Wallet</span>
                  </button>
                )}
                {/* <button className="primary_btn mt-5">
                  <span>Join Now!</span>
                </button> */}
              </div>
            </div>
          </div>
        </section>

        <section className="faq_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  Frequently asked questions
                </h2>
                <div
                  class="accordion custom_accordian"
                  id="faq_accordion"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is YEZB?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faq_accordion"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          YEZ BITCOIN Brings You a AI-Enabled Application
                          Development Projects.
                        </p>
                        <p className="para">
                          In today’s rapidly evolving technological landscape,
                          artificial intelligence (AI) has emerged as a
                          game-changer, transforming the way businesses operate
                          and interact with their customers. Our dedicated team
                          of experts specializes in harnessing the power of AI
                          to create innovative and intelligent applications that
                          drive business growth and enhance user experiences
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How many stages in pre-sale?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faq_accordion"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          There are 3 stages of pre-sale , and the price of YEZB
                          token will increase each stage , Learn more about
                          stages in our light paper.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Any staking plan available?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faq_accordion"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Yes, check staking plan on our light paper
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        When will I receive my pre-sale tokens?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#faq_accordion"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          On completion of 90 days you will receive every month
                          10% X 20 months YEZB token.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Can I check the status of my purchase?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#faq_accordion"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Yes, you can check the status of your order on
                          dashboard. Where you will be able to view your tokens
                          and claim them whenever they become available
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
