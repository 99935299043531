import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { connection } from "../helper/connection";
import YezABI from "../ABI/YezABI.json";
import config from "../config/config";
import { convert } from "../helper/convert";
import RotateLoader from "react-spinners/RotateLoader";
import { toastAlert } from "../helper/toastAlert";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "#20403e",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Settings() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setLoading] = useState(false);
  const [reInvestAmount, setReInvestAmount] = useState(0);
  const [tokenPerDol, setTokenPerDol] = useState(0);

  useEffect(() => {
    PricePerDoller();
  }, [walletConnection.address]);
  const PricePerDoller = async () => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      var YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
      let tokenValue = await YezContract.methods.tokenPerDollar().call();
      console.log(tokenValue, "tokenValue");
      let value = Number(tokenValue) / 100;
      setTokenPerDol(value);
    }
  };

  const handleUpdateTokPrice = async () => {
    setLoading(true);
    var get = await connection();
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      if (config.adminAddress !== address) {
        setLoading(false);
        toastAlert("error", "Please connect admin wallet");
        return false;
      }
      if (reInvestAmount == "" || isNaN(reInvestAmount)) {
        setLoading(false);
        toastAlert("error", "Enter valid amount");
        return false;
      }
      if (reInvestAmount <= 0) {
        setLoading(false);
        toastAlert("error", "Amount must be greater than 0 ");
        return false;
      }
      let YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
      await YezContract.methods
        .updateTokenAmount(convert(reInvestAmount * 100).toString())
        .send({ from: address })
        .then(async (registerRes) => {
          if (registerRes.status == true && registerRes.transactionHash) {
            toastAlert("success", "Price Updated");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toastAlert("error", "Rejected by user");
        });
    }
  };
  return (
    <>
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="inner_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="">
                  <h4 className="mb-3">
                    Update Token Price{" "}
                    <span style={{ fontSize: "16px" }}>
                      (1$ = {tokenPerDol})
                    </span>
                  </h4>
                  <input
                    onChange={(e) => setReInvestAmount(e.target.value)}
                    className="primary_inp form-control mt-3"
                    placeholder="Enter Token Amount"
                  />
                  <button
                    className="primary_btn mt-3"
                    style={{ color: "#222" }}
                    onClick={handleUpdateTokPrice}
                  >
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loading && (
          <div className="loadBefore">
            <RotateLoader
              color={"#9dfffa"}
              loading={loading}
              cssOverride={override}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
    </>
  );
}
