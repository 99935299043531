import Web3 from "web3";
import config from "../config/config";
import { convert } from "./convert";
// import CryptoJS from 'crypto-js';

const SECRET_KEY = "desire#4@84j2()*#&";

export function isAddress(address) {
  var web3 = new Web3(config.rpcUrl);
  var status = web3.utils.isAddress(address);
  return status;
}

// export function encryptData(data) {
//     const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
//     return encrypted;
// }

// export function decryptData(data) {
//     const decrypted = CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8);
//     return decrypted;
// }

export async function convertToWei(amount, decimals) {
  try {
    var result = "";
    var web3 = new Web3(window.ethereum);
    if (decimals === 18) {
      result = web3.utils.toWei(amount.toString());
    } else {
      result = parseFloat(amount) * parseFloat(10 ** decimals);
    }
    return result.toString();
  } catch (err) {
    return "0";
  }
}

export function numberFloatOnly(value) {
  //eslint-disable-next-line
  const regxFormat = /^[]?\d*(?:[.,]\d*)?$/;
  var result = regxFormat.test(value);
  return result;
}

export function numberOnly(value) {
  const regxFormat = /^[0-9]*$/;
  var result = regxFormat.test(value);
  return result;
}

export function formatAddress(address) {
  try {
    var addr = address.substring(0, 16);
    var addr1 = address.substring(30, 42);
    var concat = addr + "...." + addr1;
    return concat;
  } catch (err) {
    return "";
  }
}

export function shortText(address) {
  try {
    var addr = address.substring(0, 6);
    var addr1 = address.substring(36, 42);
    var concat = addr + "...." + addr1;
    return concat;
  } catch (err) {
    return "";
  }
}

export function toFixedWithoutRound(amount, dec = 2) {
  try {
    const calcDec = Math.pow(10, dec);
    var withoutFixed = Math.trunc(amount * calcDec) / calcDec;
    withoutFixed = isNumberCheck(withoutFixed);
    return withoutFixed;
  } catch (err) {
    return 0;
  }
}

export function isNumberCheck(amount) {
  var numberVal = amount;
  var convertamt =
    isFinite(numberVal) && numberVal > 0 && numberVal !== "Infinity"
      ? numberVal
      : 0;
  return convertamt;
}

export function formatData(data) {
  try {
    var field = data.field;
    var fstart = parseInt(data.fstart);
    var fend = parseInt(data.fend);
    var lstart = parseInt(data.lstart);
    var lend = parseInt(data.lend);
    var addr = field.substring(fstart, fend);
    var addr1 = field.substring(lstart, lend);
    var concat = addr + "...." + addr1;
    return concat;
  } catch (err) {
    return "";
  }
}

export function formatNumber(num, defaultFixed) {
  try {
    if (defaultFixed && parseInt(defaultFixed) > 0) {
      defaultFixed = parseInt(defaultFixed);
    } else {
      defaultFixed = 5;
    }

    var numval = num.toString();
    numval = convert(numval);
    var chkDeci = numval.split(".");
    var returnNum = num;
    if (chkDeci.length === 2) {
      if (defaultFixed < chkDeci[1].length) {
        returnNum = toFixedWithoutRound(numval, defaultFixed);
      } else {
        var fix = chkDeci[1].length;
        returnNum = toFixedWithoutRound(numval, fix);
      }
    }
    returnNum = convert(returnNum);
    return returnNum;
  } catch (err) {
    return 0;
  }
}

export async function getFormatMulticall(results, name, pos) {
  try {
    var returnVal =
      results &&
      results.results &&
      results.results[name] &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext[pos] &&
      results.results[name].callsReturnContext[pos].returnValues &&
      results.results[name].callsReturnContext[pos].returnValues[0]
        ? results.results[name].callsReturnContext[pos].returnValues[0]
        : "";
    return returnVal;
  } catch (err) {
    return "";
  }
}

export async function getFormatMulticall1(results, name, pos) {
  try {
    var returnVal =
      results &&
      results.results &&
      results.results[name] &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext &&
      results.results[name].callsReturnContext[pos] &&
      results.results[name].callsReturnContext[pos].returnValues &&
      results.results[name].callsReturnContext[pos].returnValues
        ? results.results[name].callsReturnContext[pos].returnValues
        : "";
    return returnVal;
  } catch (err) {
    return "";
  }
}

export function getParameterByName(name, url = window.location.href) {
  /* eslint-disable-next-line */
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&;]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
