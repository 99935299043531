import { Multicall } from "ethereum-multicall";
import { connection } from "../helper/connection";
import { getFormatMulticall } from "../helper/custom";

import config from "../config/config";

import YezABI from "../ABI/YezABI.json";
import USDTTokenABIABI from "../ABI/USDTTokenABI.json";
import YezTokenABI from "../ABI/YezTokenABI.json";

const abiDecoder = require("abi-decoder");
abiDecoder.addABI(YezABI);

export async function getAllowanceBalance() {
  try {
    var get = await connection();
    var web3 = get.web3;

    const multicall = new Multicall({
      web3Instance: web3
    });
    var address = get.address;
    var Contract = [
      {
        reference: "balanceOf",
        contractAddress: config.USDTToken,
        abi: USDTTokenABIABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address]
          },
          {
            reference: "allowance",
            methodName: "allowance",
            methodParameters: [address, config.USDTToken]
          }
        ]
      },
      {
        reference: "balanceOf1",
        contractAddress: config.YezToken,
        abi: YezTokenABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address]
          }
        ]
      }
    ];

    const results = await multicall.call(Contract);
    console.log(results, "results");
    var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
    balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
    var allowance = await getFormatMulticall(results, "balanceOf", 1);
    allowance = parseInt(allowance.hex) / 10 ** 18;
    var balanceOf1 = await getFormatMulticall(results, "balanceOf1", 0);
    balanceOf1 = parseInt(balanceOf1.hex) / 10 ** 18;
    var getBalance = await web3.eth.getBalance(address);
    getBalance = parseFloat(getBalance);
    getBalance = getBalance / 10 ** 18;
    console.log(balanceOf, allowance, getBalance, "wwwwww");
    return {
      balanceOf,
      balanceOf1,
      allowance,
      bnbBal: getBalance
    };
  } catch (err) {
    console.log(err, "getAllowanceBalance");
    return {
      balanceOf: 0,
      allowance: 0,
      bnbBal: getBalance
    };
  }
}

export async function getAllowanceValue(spender) {
  try {
    var get = await connection();
    var web3 = get.web3;

    const multicall = new Multicall({
      web3Instance: web3
    });
    var address = get.address;
    var Contract = [
      {
        reference: "balanceOf",
        contractAddress: config.USDTToken,
        abi: USDTTokenABIABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address]
          },
          {
            reference: "allowance",
            methodName: "allowance",
            methodParameters: [address, spender]
          }
        ]
      }
    ];

    const results = await multicall.call(Contract);
    var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
    balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
    var allowance = await getFormatMulticall(results, "balanceOf", 1);
    allowance = parseInt(allowance.hex) / 10 ** 18;

    var getBalance = await web3.eth.getBalance(address);
    getBalance = parseFloat(getBalance);
    getBalance = getBalance / 10 ** 18;

    return {
      balanceOf,
      allowance,
      bnbBal: getBalance
    };
  } catch (err) {
    console.log(err, "getAllowanceBalance");
    return {
      balanceOf: 0,
      allowance: 0,
      bnbBal: getBalance
    };
  }
}
