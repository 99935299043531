import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createConfig, configureChains } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { bsc, bscTestnet, sepolia } from "wagmi/chains";
import { Provider } from "react-redux";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import Home from "./pages/home.js";
import Dashboard from "./pages/dashboard";
import store from "./store";
import Settings from "./pages/Settings.js";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet, sepolia],
  [
    infuraProvider({
      apiKey: "cb89ebb21cdccb2e1b591e189e27706a",
    }),
    publicProvider(),
  ]
);

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        version: 2,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:address", element: <Home /> },
    { path: "/settingsyezbitcoin", element: <Settings /> },
    { path: "/dashboard", element: <Dashboard /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <Provider store={store}>
        <WagmiConfig config={wconfigInitial}>
          <ToastContainer />
          <App />
        </WagmiConfig>
      </Provider>
    </Router>
  );
};

export default AppWrapper;
